export default {
  "API_KEY": "4aiotqsUeS98QZYqpYSRJ2EVUllFliU853A1YYEJ",
  "API_URL": "https://scanner-api-admin.asqq.io",
  "COGNITO_APP_CLIENT_ID": "4o21nung8k2h55mtaibfqht2d4",
  "COGNITO_DOMAIN": "scanner-api-admin-prod.auth.us-east-2.amazoncognito.com",
  "COGNITO_REDIRECT_SIGN_IN": "https://scanner-admin.asqq.io/",
  "COGNITO_REDIRECT_SIGN_OUT": "https://scanner-admin.asqq.io/",
  "COGNITO_USER_POOL_ID": "us-east-2_bZGAqpSBQ",
  "REGION": "us-east-2"
}
